@charset "UTF-8";
/*------------------------------------------------------------------------------Variables */
/*----Colores----*/
/*----Breakpoints----*/
/*------------------------------------------------------------------------------Datepicker */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px; }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1; }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc; }
    .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 80px; }
    .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #ccc; }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #ccc; }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 30px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          padding: 5px 10px; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #f0f0f0; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #216ba5;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #216ba5; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #ccc; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__day {
  cursor: pointer; }
  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0; }
  .react-datepicker__day--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000; }
  .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc; }
    .react-datepicker__day--disabled:hover {
      background-color: transparent; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle; }
  .react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }

/*------------------------------------------------------------------------------Draft */
.draftJsMentionPlugin__mention__29BEd, .draftJsMentionPlugin__mention__29BEd:visited {
  color: #FAAF00;
  background: #FCF6EA;
  cursor: pointer;
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none; }

.draftJsMentionPlugin__mention__29BEd:hover, .draftJsMentionPlugin__mention__29BEd:focus {
  color: #FAAF00;
  background: #FCF6EA;
  outline: 0;
  /* reset for :focus */ }

.draftJsMentionPlugin__mention__29BEd:active {
  color: #FAAF00;
  background: #FCF6EA; }

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  padding: 5px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56); }

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
  background-color: #cce7ff; }

.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  background-color: #e6f3ff; }

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em; }

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px; }

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px gainsboro;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0); }

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ABABAB;
  background: #fefefe; }

.editor :global(.public-DraftEditor-content) {
  min-height: 140px; }

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block; }

.draftJsToolbar__button__qi1gf {
  background-color: transparent;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px; }

.draftJsToolbar__button__qi1gf svg {
  fill: #888; }

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  outline: 0;
  /* reset for :focus */ }

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444; }

.draftJsToolbar__active__3qcpF svg {
  fill: #444; }

.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em; }

.DraftEditor-root + .draftJsToolbar__toolbar__dNtBH {
  position: absolute;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0); }

.draftJsToolbar__toolbar__dNtBH {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px gainsboro;
  z-index: 2;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px 5px 0 0;
  border-color: #c3c3c3;
  border-bottom: 0 none; }

.draftJsToolbar__toolbar__dNtBH + .box {
  border-radius: 0 0 5px 5px; }

.draftJsToolbar__toolbar__dNtBH:after, .draftJsToolbar__toolbar__dNtBH:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px; }

.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px; }

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block; }

.draftJsToolbar__button__qi1gf {
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px; }

.draftJsToolbar__button__qi1gf svg {
  fill: #888; }

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  outline: 0;
  /* reset for :focus */ }

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444; }

.draftJsToolbar__active__3qcpF svg {
  fill: #444; }

.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em; }

.draftJsToolbar__toolbar__dNtBH {
  border: 1px solid #ddd;
  background: #fff;
  z-index: 2;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px 5px 0 0;
  border-color: #c3c3c3;
  border-bottom: 0 none; }

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px; }

.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px; }

/*------------------------------------------------------------------------------Animaciones */
.animated {
  animation-duration: 1s;
  visibility: hidden !important; }

.animated-box {
  animation-duration: 1s;
  animation-fill-mode: both;
  visibility: hidden !important; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

.in {
  -webkit-animation-name: in;
  animation-name: in;
  animation-duration: 3s; }

@keyframes in {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
  visibility: visible !important; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
  visibility: visible !important; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  visibility: visible !important; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  visibility: visible !important; }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  visibility: visible !important; }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  visibility: visible !important; }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  visibility: visible !important; }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  visibility: visible !important; }

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

@-webkit-keyframes slide {
  from {
    left: 10%; }
  to {
    left: 90%; } }

@keyframes slide {
  from {
    left: 10%; }
  to {
    left: 90%; } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*------------------------------------------------------------------------------Normalizador */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

button, html, input, select, textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

p, pre {
  margin: 1em 0; }

code, kbd, pre, samp {
  font-family: monospace,serif;
  _font-family: 'courier new',monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:after, q:before {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

dl, menu, ol, ul {
  margin: 0; }

dd {
  margin: 0 0 0 40px; }

menu, ol, ul {
  padding: 0; }

nav ol, nav ul {
  list-style: none;
  list-style-image: none; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure, form {
  margin: 0; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled], html input[disabled] {
  cursor: not-allowed; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type=search] {
  -webkit-appearance: textfield; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button, html, input, select, textarea {
  color: #222; }

body {
  font-size: 1em;
  line-height: 1.5; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  white-space: nowrap;
  border-radius: 4px; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #ffffff;
  background-color: #333333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.428571429;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px; }

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0; }

/*** print */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  blockquote, pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: .5cm; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/*------------------------------------------------------------------------------Mixins */
/*------------------------------------------------------------------------------Grillas */
.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.row [class^="grid"] {
  display: block;
  float: left;
  padding-left: 10px;
  padding-right: 10px; }

.hide {
  display: none !important; }

.grid-1 {
  width: 8.33333%; }

.grid-offset-1 {
  margin-left: 8.33333%; }

.grid-pull-1 {
  position: relative;
  right: -91.66667%; }

.grid-push-1 {
  position: relative;
  left: -91.66667%; }

.grid-2 {
  width: 16.66667%; }

.grid-offset-2 {
  margin-left: 16.66667%; }

.grid-pull-2 {
  position: relative;
  right: -83.33333%; }

.grid-push-2 {
  position: relative;
  left: -83.33333%; }

.grid-3 {
  width: 25%; }

.grid-offset-3 {
  margin-left: 25%; }

.grid-pull-3 {
  position: relative;
  right: -75%; }

.grid-push-3 {
  position: relative;
  left: -75%; }

.grid-4 {
  width: 33.33333%; }

.grid-offset-4 {
  margin-left: 33.33333%; }

.grid-pull-4 {
  position: relative;
  right: -66.66667%; }

.grid-push-4 {
  position: relative;
  left: -66.66667%; }

.grid-5 {
  width: 41.66667%; }

.grid-offset-5 {
  margin-left: 41.66667%; }

.grid-pull-5 {
  position: relative;
  right: -58.33333%; }

.grid-push-5 {
  position: relative;
  left: -58.33333%; }

.grid-6 {
  width: 50%; }

.grid-offset-6 {
  margin-left: 50%; }

.grid-pull-6 {
  position: relative;
  right: -50%; }

.grid-push-6 {
  position: relative;
  left: -50%; }

.grid-7 {
  width: 58.33333%; }

.grid-offset-7 {
  margin-left: 58.33333%; }

.grid-pull-7 {
  position: relative;
  right: -41.66667%; }

.grid-push-7 {
  position: relative;
  left: -41.66667%; }

.grid-8 {
  width: 66.66667%; }

.grid-offset-8 {
  margin-left: 66.66667%; }

.grid-pull-8 {
  position: relative;
  right: -33.33333%; }

.grid-push-8 {
  position: relative;
  left: -33.33333%; }

.grid-9 {
  width: 75%; }

.grid-offset-9 {
  margin-left: 75%; }

.grid-pull-9 {
  position: relative;
  right: -25%; }

.grid-push-9 {
  position: relative;
  left: -25%; }

.grid-10 {
  width: 83.33333%; }

.grid-offset-10 {
  margin-left: 83.33333%; }

.grid-pull-10 {
  position: relative;
  right: -16.66667%; }

.grid-push-10 {
  position: relative;
  left: -16.66667%; }

.grid-11 {
  width: 91.66667%; }

.grid-offset-11 {
  margin-left: 91.66667%; }

.grid-pull-11 {
  position: relative;
  right: -8.33333%; }

.grid-push-11 {
  position: relative;
  left: -8.33333%; }

.grid-12 {
  width: 100%; }

.grid-offset-12 {
  margin-left: 100%; }

.grid-pull-12 {
  position: relative;
  right: 0%; }

.grid-push-12 {
  position: relative;
  left: 0%; }

@media only screen and (min-width: 1300px) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    right: -91.66667%; }
  .xlarge-push-1 {
    position: relative;
    left: -91.66667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-1 {
    width: 8.33333%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    right: -91.66667%; }
  .large-push-1 {
    position: relative;
    left: -91.66667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-1 {
    width: 8.33333%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    right: -91.66667%; }
  .medium-push-1 {
    position: relative;
    left: -91.66667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-1 {
    width: 8.33333%; }
  .small-offset-1 {
    margin-left: 8.33333%; }
  .small-pull-1 {
    position: relative;
    right: -91.66667%; }
  .small-push-1 {
    position: relative;
    left: -91.66667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    right: -83.33333%; }
  .xlarge-push-2 {
    position: relative;
    left: -83.33333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-2 {
    width: 16.66667%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    right: -83.33333%; }
  .large-push-2 {
    position: relative;
    left: -83.33333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-2 {
    width: 16.66667%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    right: -83.33333%; }
  .medium-push-2 {
    position: relative;
    left: -83.33333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-2 {
    width: 16.66667%; }
  .small-offset-2 {
    margin-left: 16.66667%; }
  .small-pull-2 {
    position: relative;
    right: -83.33333%; }
  .small-push-2 {
    position: relative;
    left: -83.33333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-3 {
    width: 25%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    right: -75%; }
  .xlarge-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-3 {
    width: 25%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-pull-3 {
    position: relative;
    right: -75%; }
  .large-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-3 {
    width: 25%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-pull-3 {
    position: relative;
    right: -75%; }
  .medium-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-3 {
    width: 25%; }
  .small-offset-3 {
    margin-left: 25%; }
  .small-pull-3 {
    position: relative;
    right: -75%; }
  .small-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    right: -66.66667%; }
  .xlarge-push-4 {
    position: relative;
    left: -66.66667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-4 {
    width: 33.33333%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    right: -66.66667%; }
  .large-push-4 {
    position: relative;
    left: -66.66667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-4 {
    width: 33.33333%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    right: -66.66667%; }
  .medium-push-4 {
    position: relative;
    left: -66.66667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-4 {
    width: 33.33333%; }
  .small-offset-4 {
    margin-left: 33.33333%; }
  .small-pull-4 {
    position: relative;
    right: -66.66667%; }
  .small-push-4 {
    position: relative;
    left: -66.66667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    right: -58.33333%; }
  .xlarge-push-5 {
    position: relative;
    left: -58.33333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-5 {
    width: 41.66667%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    right: -58.33333%; }
  .large-push-5 {
    position: relative;
    left: -58.33333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-5 {
    width: 41.66667%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    right: -58.33333%; }
  .medium-push-5 {
    position: relative;
    left: -58.33333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-5 {
    width: 41.66667%; }
  .small-offset-5 {
    margin-left: 41.66667%; }
  .small-pull-5 {
    position: relative;
    right: -58.33333%; }
  .small-push-5 {
    position: relative;
    left: -58.33333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-6 {
    width: 50%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    right: -50%; }
  .xlarge-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-6 {
    width: 50%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-pull-6 {
    position: relative;
    right: -50%; }
  .large-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-6 {
    width: 50%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-pull-6 {
    position: relative;
    right: -50%; }
  .medium-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-6 {
    width: 50%; }
  .small-offset-6 {
    margin-left: 50%; }
  .small-pull-6 {
    position: relative;
    right: -50%; }
  .small-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    right: -41.66667%; }
  .xlarge-push-7 {
    position: relative;
    left: -41.66667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-7 {
    width: 58.33333%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    right: -41.66667%; }
  .large-push-7 {
    position: relative;
    left: -41.66667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-7 {
    width: 58.33333%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    right: -41.66667%; }
  .medium-push-7 {
    position: relative;
    left: -41.66667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-7 {
    width: 58.33333%; }
  .small-offset-7 {
    margin-left: 58.33333%; }
  .small-pull-7 {
    position: relative;
    right: -41.66667%; }
  .small-push-7 {
    position: relative;
    left: -41.66667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    right: -33.33333%; }
  .xlarge-push-8 {
    position: relative;
    left: -33.33333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-8 {
    width: 66.66667%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    right: -33.33333%; }
  .large-push-8 {
    position: relative;
    left: -33.33333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-8 {
    width: 66.66667%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    right: -33.33333%; }
  .medium-push-8 {
    position: relative;
    left: -33.33333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-8 {
    width: 66.66667%; }
  .small-offset-8 {
    margin-left: 66.66667%; }
  .small-pull-8 {
    position: relative;
    right: -33.33333%; }
  .small-push-8 {
    position: relative;
    left: -33.33333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-9 {
    width: 75%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    right: -25%; }
  .xlarge-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-9 {
    width: 75%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-pull-9 {
    position: relative;
    right: -25%; }
  .large-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-9 {
    width: 75%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-pull-9 {
    position: relative;
    right: -25%; }
  .medium-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-9 {
    width: 75%; }
  .small-offset-9 {
    margin-left: 75%; }
  .small-pull-9 {
    position: relative;
    right: -25%; }
  .small-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    right: -16.66667%; }
  .xlarge-push-10 {
    position: relative;
    left: -16.66667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-10 {
    width: 83.33333%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    right: -16.66667%; }
  .large-push-10 {
    position: relative;
    left: -16.66667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-10 {
    width: 83.33333%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    right: -16.66667%; }
  .medium-push-10 {
    position: relative;
    left: -16.66667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-10 {
    width: 83.33333%; }
  .small-offset-10 {
    margin-left: 83.33333%; }
  .small-pull-10 {
    position: relative;
    right: -16.66667%; }
  .small-push-10 {
    position: relative;
    left: -16.66667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    right: -8.33333%; }
  .xlarge-push-11 {
    position: relative;
    left: -8.33333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-11 {
    width: 91.66667%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    right: -8.33333%; }
  .large-push-11 {
    position: relative;
    left: -8.33333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-11 {
    width: 91.66667%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    right: -8.33333%; }
  .medium-push-11 {
    position: relative;
    left: -8.33333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%;
    padding: 0 !important; } }

@media only screen and (max-width: 767px) {
  .small-11 {
    width: 91.66667%; }
  .small-offset-11 {
    margin-left: 91.66667%; }
  .small-pull-11 {
    position: relative;
    right: -8.33333%; }
  .small-push-11 {
    position: relative;
    left: -8.33333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%;
    padding: 0 !important; } }

/*------------------------------------------------------------------------------Auxiliares */
.to-the-left {
  float: left; }

.to-the-right {
  float: right; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.relative-position {
  position: relative; }

.no-gutter-left {
  padding-left: 0 !important; }

.no-gutter-right {
  padding-right: 0 !important; }

.d-flex {
  display: flex; }

.w-50 {
  width: 50%; }

.inline-list {
  display: table;
  width: 100%; }
  .inline-list li {
    display: table-cell;
    vertical-align: middle; }
  .inline-list.floated {
    display: block; }
    .inline-list.floated li {
      display: block;
      float: left; }

.vertical-list {
  display: block; }
  .vertical-list li {
    display: block; }
    .vertical-list li a {
      display: block;
      padding: 10px; }

.user-list {
  background-color: #FFF;
  margin: 0;
  padding: 0;
  border-top: 1px solid #C3C3C3; }
  .user-list li {
    display: block;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid #C3C3C3;
    font-size: 0.85714rem; }
    .user-list li:last-child {
      border-bottom: 0; }

.user-name {
  display: block;
  font-weight: bold; }

.user-indicators {
  position: absolute;
  bottom: 20px;
  right: 10px; }

.delay-animation.delay-0 {
  animation-delay: 0ms; }

.delay-animation.delay-1 {
  animation-delay: 50ms; }

.delay-animation.delay-2 {
  animation-delay: 100ms; }

.delay-animation.delay-3 {
  animation-delay: 150ms; }

.delay-animation.delay-4 {
  animation-delay: 200ms; }

.delay-animation.delay-5 {
  animation-delay: 250ms; }

.delay-animation.delay-6 {
  animation-delay: 300ms; }

.delay-animation.delay-7 {
  animation-delay: 350ms; }

.delay-animation.delay-8 {
  animation-delay: 400ms; }

.delay-animation.delay-9 {
  animation-delay: 450ms; }

.delay-animation.delay-10 {
  animation-delay: 500ms; }

.delay-animation.delay-11 {
  animation-delay: 550ms; }

.m-r-0 {
  margin-right: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-t-0 {
  margin-top: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.p-t-0 {
  padding-top: 0px; }

.m-r-10 {
  margin-right: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-t-10 {
  margin-top: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-t-10 {
  padding-top: 10px; }

.m-r-20 {
  margin-right: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-t-20 {
  margin-top: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-t-20 {
  padding-top: 20px; }

.m-r-30 {
  margin-right: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-t-30 {
  margin-top: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.p-t-30 {
  padding-top: 30px; }

.m-r-40 {
  margin-right: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-t-40 {
  margin-top: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.p-t-40 {
  padding-top: 40px; }

.m-r-50 {
  margin-right: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-t-50 {
  margin-top: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-t-50 {
  padding-top: 50px; }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc; }

.button-reset, .btn, .btn.btn-user-logout, .icon-element {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: transparent;
  border-radius: 0px;
  border: 0px;
  outline: 0px;
  padding: 0px; }

.elastic-img {
  width: 100%;
  height: auto; }

.elastic-img-limit {
  max-width: 100%;
  height: auto; }

.centered-img {
  display: block;
  margin: 0 auto; }

.clearfix, .inline-list.floated {
  *zoom: 1; }
  .clearfix:before, .inline-list.floated:before, .clearfix:after, .inline-list.floated:after {
    content: " ";
    display: table; }
  .clearfix:after, .inline-list.floated:after {
    clear: both; }

.rounded-img {
  border-radius: 100%;
  border: 1px solid #C3C3C3; }

.clear-margin {
  margin: 0; }

.margin-bottom-wrapp {
  margin-bottom: 20px; }
  .margin-bottom-wrapp.margin-bottom-wrapp-big {
    margin-bottom: 40px; }

.container {
  padding-left: 10px;
  padding-right: 10px; }

.ohidden {
  overflow: hidden; }

.hide {
  display: none; }

.wrapper {
  padding: 20px; }
  .wrapper.wrapper-vertical {
    padding-left: 0;
    padding-right: 0; }
  .wrapper.wrapper-horizontal {
    padding-top: 0;
    padding-bottom: 0; }

.box-wrapp {
  padding: 0.71429rem; }
  @media only screen and (max-width: 767px) {
    .box-wrapp {
      padding: 10px; } }

.disabled {
  opacity: 0.3;
  pointer-events: none; }

.disable-interaction {
  pointer-events: none; }

.inline-right {
  margin-left: 10px; }

.inline-left {
  margin-right: 10px; }

.inline-element {
  display: inline-block; }

.block-element {
  display: block; }

.responsive-table-holder {
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
  min-height: 200px; }

.only-on-small {
  display: none !important; }
  @media only screen and (max-width: 767px) {
    .only-on-small {
      display: block !important; } }

.full-height {
  height: 100vh; }

.vertically-center-parent {
  position: relative;
  height: 100%; }

.vertically-center-children {
  top: 50%;
  transform: translate3d(0, -50%, 0);
  position: relative; }

.bordered-left {
  border-left: 1px solid #C3C3C3; }

.bordered-bottom {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #C3C3C3; }
  .bordered-bottom.no-margin {
    margin-bottom: 0; }
  .bordered-bottom:last-child {
    border: 0 none;
    margin-bottom: 0;
    padding-bottom: 0; }

.min-box {
  min-height: 510px; }

/*------------------------------------------------------------------------------Generales */
::-webkit-scrollbar {
  display: none; }

html {
  font-size: 14px; }

body {
  font-family: "Roboto", sans-serif;
  line-height: 130%;
  background-color: #F4F4F7;
  font-size: 14px;
  -webkit-backface-visibility: hidden;
  color: #606060; }
  body.preload {
    overflow: hidden; }

button {
  font-family: "Roboto", sans-serif; }

a {
  color: #276AA6;
  text-decoration: underline;
  outline: 0 none;
  transition: all 350ms ease; }
  a:hover {
    color: #184268;
    outline: 0 none;
    text-decoration: none; }
  a:focus {
    outline: 0 none; }

ul {
  margin-left: 0; }

p {
  font-size: 1rem;
  margin: 0;
  line-height: 130%;
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

iframe {
  width: 100%; }

.email-iframe {
  border: 0 none;
  height: calc(100vh - 230px);
  overflow-x: hidden;
  overflow-y: scroll; }

.campaign-email-iframe {
  border: 1px solid #DDD;
  height: calc(100vh - 230px); }

hr {
  width: 100%;
  margin: 30px 0; }

dl:last-child {
  border-bottom: 0 none; }

dl dt, dl dd {
  display: inline-block;
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #C3C3C3; }

dl dt {
  width: 20%;
  text-align: right;
  font-weight: bold;
  padding-right: 5px; }

dl dd {
  width: 80%;
  padding-left: 5px; }

.line-list {
  list-style: none;
  border-bottom: 1px solid #C3C3C3; }
  .line-list.no-horizontal-padding li {
    padding-left: 0;
    padding-right: 0; }
  .line-list:last-child {
    border-bottom: 0 none; }
  .line-list li {
    padding: 20px;
    border-bottom: 1px solid #C3C3C3 !important; }
    .line-list li:last-child {
      border: 0 none !important; }

.definition, .term {
  display: inline-block;
  vertical-align: top; }

.term {
  font-weight: bold;
  width: 30%;
  padding-right: 10px;
  font-size: 0.85714rem; }

.definition {
  width: 70%;
  font-size: 0.85714rem; }

/*------------------------------------------------------------------------------Fondos */
/*------------------------------------------------------------------------------Headlines */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: #011F56;
  line-height: 120%;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500; }

h1 {
  font-size: 1.5rem; }

h2 {
  font-size: 1.28571rem; }

h3 {
  font-size: 1.14286rem;
  color: #606060;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 20px; }

h4 {
  font-size: 1rem;
  color: #606060;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 50px; }

h5 {
  font-size: 0.92857rem; }

h6 {
  font-size: 0.85714rem; }

.inner-content-title {
  margin-bottom: 0;
  text-transform: none; }

.inner-content-subtitle {
  display: block;
  color: #A7A7A7;
  font-size: 1.14286rem; }

.fallback-message-holder {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0; }

.fallback-message {
  padding: 20px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.small-text-disclaimer {
  font-weight: bold;
  font-size: 0.85714rem; }
  .small-text-disclaimer.warning {
    color: #FAAF00; }
  .small-text-disclaimer.success {
    color: #38C14F; }
  .small-text-disclaimer.error {
    color: #F73742; }
  .small-text-disclaimer.inactive {
    color: #606060; }

.secondary-data {
  color: #C3C3C3;
  display: block;
  padding: 5px 0;
  font-size: 0.85714rem;
  font-weight: 500; }

.confirm-title {
  color: #276AA6;
  font-size: 14px;
  text-decoration: underline;
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 0; }

.text-green {
  color: #38C14F; }

.text-yellow {
  color: #FAAF00; }

.text-red {
  color: #F73742; }

/*------------------------------------------------------------------------------Buttons */
.btn {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: 0.85714rem;
  padding: 15px 50px;
  width: auto;
  height: auto;
  line-height: 120%;
  transition: background 300ms ease;
  color: #F2F2F2;
  transition: all 350ms ease;
  background-color: #276AA6;
  font-weight: 500;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none; }
  .btn.btn-small {
    padding: 12px 25px; }
  .btn.btn-smallest {
    padding: 10px 15px;
    text-transform: none;
    letter-spacing: 0; }
  .btn.btn-top-right {
    position: absolute;
    top: 15px;
    right: 30px; }
  .btn.btn-secondary {
    background-color: #D4E8FA;
    color: #276AA6; }
    .btn.btn-secondary:hover {
      color: #FFF; }
  .btn.btn-error {
    background-color: #FDDDDF;
    color: #F73742; }
    .btn.btn-error:hover {
      background-color: #F73742;
      color: #FFF; }
  .btn.btn-full {
    width: 100%; }
  .btn.btn-tab {
    background-color: transparent;
    border: 1px solid #C3C3C3;
    border-radius: 5px 5px 0 0;
    color: #C3C3C3;
    padding: 10px 40px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: bold;
    border-right: 0 none;
    position: relative; }
    .btn.btn-tab.active {
      background-color: #F4F4F7;
      color: #276AA6; }
      .btn.btn-tab.active:after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #F4F4F7;
        position: absolute;
        bottom: -2px;
        display: block;
        left: 0;
        z-index: 1; }
    .btn.btn-tab:last-child {
      border-right: 1px solid #C3C3C3; }
    .btn.btn-tab:hover {
      color: #276AA6;
      background-color: #F4F4F7; }
  .btn.btn-user-navigation {
    background-color: transparent;
    color: #C3C3C3;
    font-weight: normal;
    letter-spacing: none;
    font-size: 1rem;
    text-transform: none;
    padding: 15px 25px; }
    .btn.btn-user-navigation.active {
      color: #276AA6;
      height: 100%;
      border: 1px solid #C3C3C3;
      border-radius: 5px 5px 0px 0px;
      position: relative; }
      .btn.btn-user-navigation.active:after {
        content: "";
        height: 8px;
        background-color: #FFF;
        bottom: -4px;
        width: 100%;
        left: 0px;
        display: block;
        position: absolute;
        z-index: 2; }
    .btn.btn-user-navigation:hover {
      background-color: transparent;
      color: #276AA6; }
  .btn.btn-user-logout {
    background-image: url("../images/logout.svg");
    background-repeat: no-repeat;
    background-position: center center;
    padding: 8px 25px;
    margin-right: 10px; }
    .btn.btn-user-logout:hover {
      background-color: transparent; }
  .btn.btn-table {
    background-color: #F5F5F5;
    color: #276AA6;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-right: 5px; }
    .btn.btn-table:last-child {
      margin-right: 0; }
    .btn.btn-table:hover {
      background-color: #cfcfcf; }
  .btn.btn-user-actions {
    background-color: transparent;
    padding: 5px; }
    .btn.btn-user-actions:hover {
      background-color: transparent; }
    .btn.btn-user-actions.active {
      border: 1px solid #C3C3C3;
      border-radius: 5px 5px 0 0;
      border-bottom: 0 none;
      background-color: #FFF; }
  .btn.btn-icon {
    background-color: transparent;
    border: 0 none;
    padding: 0; }
    .btn.btn-icon:hover {
      background-color: transparent;
      opacity: 0.8; }
  .btn.btn-back {
    display: inline-block;
    margin-right: 10px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    margin-top: 7px; }
  .btn.btn-link {
    color: #276AA6;
    font-size: 1rem;
    text-decoration: underline;
    background-color: transparent;
    width: auto;
    text-transform: none;
    letter-spacing: 0px;
    font-weight: normal; }
    .btn.btn-link:hover {
      color: #184268;
      text-decoration: none;
      background-color: transparent; }
  .btn.rounded-btn {
    border-radius: 100%;
    background-color: #FFF;
    border: 1px solid #C3C3C3;
    text-align: center;
    width: 32px;
    height: 32px;
    padding: 0; }
    .btn.rounded-btn.active {
      border-color: #FAAF00;
      background-color: #FCF6EA; }
    .btn.rounded-btn:hover {
      background-color: #f2f2f2; }
  .btn:hover {
    background-color: #225d91; }

/*------------------------------------------------------------------------------Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px; }
  .tooltip.in-field {
    position: absolute;
    bottom: 10px;
    right: 0; }
  .tooltip:hover .tooltip-content {
    opacity: 1;
    visibility: visible; }

.tooltip-content {
  opacity: 0;
  visibility: hidden;
  transition: all 350ms ease;
  position: absolute;
  background-color: #5393CC;
  color: #FFF;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  bottom: calc(100% + 10px);
  left: calc(50% - 100px);
  width: 200px;
  text-align: center;
  font-size: 0.85714rem;
  font-weight: normal;
  z-index: 9; }
  .tooltip-content.small {
    width: auto;
    padding: 0px 10px;
    font-size: 0.71429rem; }
  .tooltip-content.up-left {
    bottom: calc(100% + 10px);
    left: auto;
    right: 4px;
    width: auto;
    padding: 0px 10px;
    border-radius: 5p 5px 0 5px; }
    .tooltip-content.up-left:after {
      right: 4px;
      left: auto; }
  .tooltip-content:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #5393CC;
    position: absolute;
    top: 100%;
    left: calc(50% - 5px); }
  .error .tooltip-content {
    background-color: #F73742; }
    .error .tooltip-content:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #F73742; }

/*------------------------------------------------------------------------------Simbology */
.simbology-holder {
  position: absolute;
  top: 0;
  right: 0; }

.simbology {
  display: inline-block;
  font-weight: normal;
  font-size: 1rem;
  margin-left: 20px; }

.simbology-color {
  background-color: gray;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  display: inline-block; }

/*------------------------------------------------------------------------------Charts */
.chart-holder {
  position: relative;
  min-height: 325px; }

.charts-pie {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  position: relative; }
  .charts-pie .chart-info {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 70px);
    width: 140px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 0; }
    .charts-pie .chart-info h4 {
      margin: 0; }
    .charts-pie .chart-info p {
      font-size: 10px;
      margin-bottom: 10px; }

.recharts-cartesian-grid-horizontal line {
  stroke: #F0F0F0; }
  .recharts-cartesian-grid-horizontal line:first-child {
    stroke: #C3C3C3;
    stroke-width: 2px; }

.recharts-cartesian-axis-tick text {
  fill: #C3C3C3;
  font-size: 12px;
  font-weight: 500; }

.recharts-cartesian-axis-tick-line {
  fill: #C3C3C3; }

.recharts-default-tooltip {
  fill: #C3C3C3; }

.recharts-line-curve {
  stroke-width: 2; }

.recharts-label {
  fill: #C3C3C3;
  font-size: 12px; }

.chart-vertical .recharts-cartesian-grid-horizontal line:first-child {
  stroke: #F0F0F0;
  stroke-width: 1px; }

.recharts-tooltip-wrapper {
  z-index: 5; }

/*------------------------------------------------------------------------------Icons */
.icon-element {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-image: url("../images/sprites-3.svg");
  background-repeat: no-repeat;
  background-position: center center;
  speak: none;
  /*------------------Generales----*/ }
  .icon-element.inline-left {
    margin-right: 5px; }
  .icon-element.inline-right {
    margin-left: 5px; }
  .icon-element.centered {
    margin-left: auto;
    margin-right: auto;
    display: block; }
  .icon-element.check {
    background-position: -8px -6px;
    width: 20px;
    height: 20px; }
  .icon-element.edit {
    background-position: -11px -412px;
    width: 12px;
    height: 12px; }
  .icon-element.close {
    background-position: -143px -412px;
    width: 11px;
    height: 11px; }
  .icon-element.disabled-check {
    background-position: -72px -6px;
    width: 20px;
    height: 20px; }
  .icon-element.info {
    background-position: -134px -6px;
    width: 20px;
    height: 20px; }
  .icon-element.rounded {
    border-radius: 100%; }
  .icon-element.red {
    background-color: #F73742; }
  .icon-element.light-blue {
    background-color: #5393CC; }
  .icon-element.field-icon {
    position: absolute;
    right: 0;
    bottom: 10px; }
  .icon-element.inner-field-icon {
    position: absolute;
    left: 5px;
    bottom: 3px;
    z-index: 1; }
    .icon-element.inner-field-icon + input {
      padding-left: 30px; }
  .icon-element.dashboard {
    background-position: -9px -142px;
    width: 18px;
    height: 18px; }
    .active .icon-element.dashboard {
      background-position: -9px -73px; }
  .icon-element.templates {
    background-position: -76px -142px;
    width: 18px;
    height: 18px; }
    .active .icon-element.templates {
      background-position: -76px -73px; }
  .icon-element.campaigns {
    background-position: -144px -143px;
    width: 18px;
    height: 18px; }
    .active .icon-element.campaigns {
      background-position: -144px -73px; }
  .icon-element.clients {
    background-position: -212px -141px;
    width: 18px;
    height: 18px; }
    .active .icon-element.clients {
      background-position: -212px -72px; }
  .icon-element.arrow-down {
    background-position: -199px -15px;
    width: 11px;
    height: 6px; }
  .icon-element.settings {
    background-position: -259px -8px;
    width: 15px;
    height: 16px; }
  .icon-element.calendar {
    background-position: -9px -209px;
    width: 16px;
    height: 19px; }
  .icon-element.search {
    background-position: -76px -209px;
    width: 16px;
    height: 20px; }
  .icon-element.plus {
    background-position: -143px -208px;
    width: 16px;
    height: 16px; }
  .icon-element.excluded {
    background-position: -9px -275px;
    width: 16px;
    height: 16px; }
    .icon-element.excluded.active {
      background-position: -9px -345px; }
  .icon-element.conversation {
    background-position: -76px -275px;
    width: 16px;
    height: 16px; }
    .icon-element.conversation.active {
      background-position: -76px -345px; }
  .icon-element.voucher {
    background-position: -142px -275px;
    width: 16px;
    height: 16px; }
    .icon-element.voucher.active {
      background-position: -142px -345px; }
  .icon-element.paid {
    background-position: -208px -274px;
    width: 20px;
    height: 20px; }
    .icon-element.paid.active {
      background-position: -208px -344px; }
  .icon-element.points {
    background-position: -400px -6px;
    width: 20px;
    height: 20px; }
  .icon-element.back {
    background-position: -137px -475px;
    width: 20px;
    height: 20px; }
  .icon-element.card-grid {
    background-position: -307px -479px;
    width: 15px;
    height: 16px; }
    .active .icon-element.card-grid {
      background-position: -262px -479px; }
  .icon-element.table-grid {
    background-position: -399px -479px;
    width: 15px;
    height: 16px; }
    .active .icon-element.table-grid {
      background-position: -354px -479px; }
  .icon-element.send {
    background-position: -445px -480px;
    width: 16px;
    height: 16px; }

.icon-element-1 {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-image: url("../images/sprites-5.svg");
  background-repeat: no-repeat;
  background-position: center center;
  speak: none; }
  .icon-element-1.templates-2 {
    width: 19px;
    height: 19px; }

.logo-icon {
  width: 50px;
  height: 50px;
  background-image: url("../images/wom.png");
  background-size: cover;
  background-repeat: no-repeat; }

/*------------------------------------------------------------------------------Forms */
input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  transition: all 350ms ease;
  height: 30px;
  background-color: #FFF;
  font-size: 0.85714rem;
  font-family: "Roboto", sans-serif;
  background-color: #FFF;
  border: 0 none;
  border-bottom: 1px solid #919191;
  transition: all 350ms ease; }
  input.inline-field, select.inline-field, textarea.inline-field {
    display: inline-block;
    width: auto;
    min-width: 100px; }
  input:hover, select:hover, textarea:hover {
    border-color: #276AA6; }
  input:focus, select:focus, textarea:focus {
    outline: 0 none;
    border-color: #276AA6;
    background-color: #f6f6f6; }
  input.bordered, select.bordered, textarea.bordered {
    border: 1px solid #C3C3C3; }
    input.bordered:hover, select.bordered:hover, textarea.bordered:hover {
      border-color: #276AA6; }

input[type="radio"], input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #C3C3C3;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle; }
  input[type="radio"]:checked, input[type="checkbox"]:checked {
    background-color: #276AA6; }

input[readOnly] {
  background-color: #f6f6f6; }

input[type="checkbox"] {
  border-radius: 0; }

textarea {
  min-height: 130px;
  min-width: 300px;
  border: 1px solid #C3C3C3;
  border-radius: 5px; }

select {
  padding: 5px;
  padding-right: 25px;
  position: relative;
  background-image: url("../images/selector.svg");
  background-position: 99% 11px;
  background-repeat: no-repeat;
  font-size: 0.85714rem;
  border-radius: 0; }
  select.filter-field {
    background-position: 95% 7px; }

.invalid-input input {
  border-color: #F73742 !important; }

label {
  display: block;
  position: relative;
  margin-bottom: 30px; }
  label:last-child {
    margin-bottom: 0; }

.label-like {
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
  font-size: 0.85714rem; }
  .invalid-input .label-like {
    color: #F73742; }

.checkbox-holder {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle; }
  .checkbox-holder input {
    display: none; }
    .checkbox-holder input:checked + .slider {
      background-color: #38C14F; }
      .checkbox-holder input:checked + .slider:before {
        transform: translateX(26px); }
  .checkbox-holder .slider {
    width: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    padding: 17px;
    transition: .4s;
    border-radius: 34px; }
    .checkbox-holder .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 100%; }
  .checkbox-holder + .label-like {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-bottom: 0;
    width: calc(100% - 65px); }

.button-input input {
  width: calc(100% - 30px);
  display: inline-block; }

.button-input .btn {
  width: 30px;
  height: 30px;
  padding: 0; }

.error-message {
  font-size: 0.85714rem;
  color: #F73742; }

.form-button-holder {
  border-top: 1px solid #C3C3C3;
  padding: 10px;
  position: absolute;
  width: 100%;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  border-radius: 0px 0px 5px 5px; }

.filter-field {
  border: 1px solid #C3C3C3;
  border-radius: 5px; }

.step-list {
  display: table;
  padding: 0;
  width: 100%;
  max-width: 55%;
  list-style: none;
  counter-reset: item;
  padding: 10px;
  margin: 0 auto; }
  .step-list.full {
    width: 100%;
    max-width: 100%; }
  .step-list.inner {
    display: block;
    padding: 0px; }
    .step-list.inner li {
      padding: 15px 30px;
      display: block;
      border-bottom: 0;
      margin-bottom: 0; }
      .step-list.inner li h4 {
        font-weight: bold;
        margin-bottom: 10px;
        color: #9c9b9b;
        font-size: 1rem; }
  .step-list > li {
    display: table-cell;
    counter-increment: item;
    position: relative;
    padding: 10px 30px;
    border-bottom: 2px solid #C3C3C3; }
    .step-list > li a {
      color: #919191;
      text-decoration: none;
      display: block;
      text-align: center;
      font-size: 0.85714rem; }
      .step-list > li a.confirm-title {
        color: #276AA6;
        text-decoration: underline; }
    .step-list > li:last-child a {
      border-right: 0 none; }
    .step-list > li.active {
      border-color: #276AA6; }
      .step-list > li.active a {
        font-weight: bold;
        color: #276AA6; }
      .step-list > li.active:before {
        background-color: #276AA6; }
    .step-list > li.success {
      border-color: #38C14F; }
      .step-list > li.success a {
        font-weight: bold;
        color: #38C14F; }
      .step-list > li.success:before {
        background-color: #38C14F; }
  @media only screen and (min-width: 1300px) {
    .step-list {
      max-width: 45%; } }

.steps-panel-holder {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: top;
  padding: 20px 0; }

.step-form-panel {
  border-left: 1px solid #C3C3C3; }
  .step-form-panel.active {
    padding: 50px 30px; }

.field-disclaimer {
  font-size: 0.85714rem;
  color: #A7A7A7;
  font-style: italic;
  display: block;
  margin-top: 5px; }

.upload-button-holder .btn {
  border-radius: 5px 0 0 5px; }

.upload-button-holder .fake-input {
  border-bottom: 1px solid #C3C3C3;
  display: inline-block;
  width: calc(100% - 165px);
  vertical-align: top;
  padding: 7px;
  border-radius: 0;
  white-space: nowrap;
  font-size: 0.85714rem; }

.day {
  font-size: 0.85714rem;
  border-radius: 100%;
  text-align: center;
  width: 25px;
  height: 25px;
  border: 1px solid #919191;
  background-color: transparent;
  display: inline-block;
  margin-right: 5px;
  color: #919191;
  text-transform: uppercase;
  padding: 0;
  line-height: 200%; }
  .day:focus {
    outline: 0 none; }
  .day.active {
    border-color: #D4E8FA;
    background-color: #D4E8FA;
    color: #276AA6; }
  .day:last-child {
    margin-right: 0; }

.form-wrapper {
  max-width: 70%;
  margin: 0 auto; }
  .form-wrapper.big {
    max-width: 90%; }

.inline-field {
  display: inline-block;
  width: auto;
  margin-right: 10px; }

/*------------------------------------------------------------------------------Backgrounds */
.gradient-blue-bg {
  background: linear-gradient(to bottom, #276AA6 0%, #143553 100%); }

.gray-bg {
  background-color: #F4F4F7; }

/*------------------------------------------------------------------------------Header */
.logo {
  font-size: 0;
  color: transparent; }
  .logo img {
    max-width: 260px; }

.user-navigation {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center; }
  .user-navigation span {
    margin-right: 20px; }
  .user-navigation .dropdown-holder {
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0; }

/*------------------------------------------------------------------------------Containers */
.box {
  background-color: #FFFFFF;
  border: 1px solid #C3C3C3;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  padding: 20px; }
  .box.inner {
    padding: 0; }
  .box.tab-box {
    border-radius: 0 0 5px 5px; }
  .box.no-shadow {
    box-shadow: none; }
  .box.no-padding {
    padding: 0; }
  .box.box-form {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto; }
    @media only screen and (min-width: 1300px) {
      .box.box-form {
        max-width: 50%; } }
  .box.table-view {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 5px;
    padding: 0;
    border-radius: 0 5px 5px 0px; }
    .box.table-view .campaign-box-header-indicator {
      position: static;
      padding-top: 15px; }
    .box.table-view .campaign-box-wrapper {
      display: table-cell;
      vertical-align: middle;
      border-bottom: 0 none;
      border-right: 1px solid #C3C3C3;
      padding: 15px; }
      .box.table-view .campaign-box-wrapper:last-child {
        border: 0 none; }
      .box.table-view .campaign-box-wrapper h4 {
        display: none; }

.campaign-table {
  position: relative;
  padding-left: 30px;
  padding-top: 15px; }
  .campaign-table:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 0;
    height: 0;
    border-right: 40px solid transparent; }
  .campaign-table.success:before {
    border-top: 40px solid #98e1a4; }
  .campaign-table.inactive:before {
    border-top: 40px solid #e0e0e0; }
  .campaign-table.finished:before {
    border-top: 40px solid #adceec; }
  .campaign-table.error:before {
    border-top: 40px solid #fb999f; }

.campaign-box {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 0;
  border-radius: 0 5px 5px 5px;
  cursor: pointer;
  transition: all 350ms ease; }
  .campaign-box:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transform: scale(1.05, 1.05); }
  .campaign-box.success .campaign-box-header:before {
    background-color: #98e1a4; }
  .campaign-box.inactive .campaign-box-header:before {
    background-color: #e0e0e0; }
  .campaign-box.finished .campaign-box-header:before {
    background-color: #adceec; }
  .campaign-box.error .campaign-box-header:before {
    background-color: #fb999f; }
  .campaign-box .campaign-box-header {
    position: relative; }
    .campaign-box .campaign-box-header:before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0px;
      width: 5px; }

.campaign-box-wrapper {
  padding: 15px;
  border-bottom: 1px solid #C3C3C3; }
  .campaign-box-wrapper:last-child {
    border: 0 none; }

.campaign-box-header {
  padding: 10px 20px;
  border-bottom: 1px solid #C3C3C3; }
  .campaign-box-header h2 {
    margin-bottom: 0;
    font-size: 1rem; }

.campaign-box-header-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #276AA6;
  font-size: 0.85714rem;
  font-weight: 500; }
  .campaign-box-header-indicator.static {
    position: static; }

.campaign-client-visualization-header {
  font-size: 0.85714rem;
  font-weight: 500;
  position: relative;
  /* Estilo para el ícono .sms dentro del contenedor */ }
  .campaign-client-visualization-header .sms {
    position: absolute;
    width: 28px;
    height: 28px;
    background-image: url("../images/sms.png");
    background-size: cover;
    background-repeat: no-repeat;
    right: 5%; }
  .campaign-client-visualization-header .whatsapp {
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url("../images/whatsaap.png");
    background-size: cover;
    background-repeat: no-repeat;
    right: 6%; }
  .campaign-client-visualization-header .email {
    position: absolute;
    width: 26px;
    height: 26px;
    background-image: url("../images/email.png");
    background-size: cover;
    background-repeat: no-repeat;
    right: 7%; }
  .campaign-client-visualization-header .voz {
    position: absolute;
    width: 45px;
    height: 45px;
    background-image: url("../images/voz.png");
    background-size: cover;
    background-repeat: no-repeat;
    top: -13px;
    right: 1%; }

.percent-bar-holder {
  position: relative;
  height: 30px;
  margin-top: 10px; }

.percent-bar {
  height: 1px;
  width: 100%;
  position: absolute;
  top: 5px;
  background-color: #79B1E3; }

.percent-bar-completed {
  width: 50%;
  display: block;
  height: 5px;
  background-color: #37C3F7;
  position: absolute;
  top: -3px;
  border-radius: 5px; }

.percent-bar-values-holder {
  margin: 10px 0;
  position: relative;
  position: absolute;
  width: 100%;
  height: 10px; }

.percent-bar-value {
  font-weight: bold;
  color: #C3C3C3;
  position: absolute;
  left: 0; }
  .percent-bar-value.right {
    left: auto;
    right: 0; }

.fieldset-box {
  border-bottom: 1px solid #C3C3C3;
  padding-bottom: 20px;
  margin-bottom: 20px; }
  .fieldset-box:last-child {
    border: 0 none;
    margin-bottom: 0; }

.box-small-centered {
  max-width: 450px;
  margin: 0 auto; }

.inner-content {
  position: relative;
  padding: 0 40px 20px 40px; }

.inner-header-content-holder {
  display: inline-block; }

.inner-header {
  background-color: #FFF;
  border-bottom: 1px solid #C3C3C3;
  padding: 20px 0;
  margin-bottom: 20px;
  position: relative; }
  .inner-header.with-tabs {
    padding-bottom: 50px; }
  .inner-header:before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 5px; }
  .inner-header.success:before {
    background-color: #98e1a4; }
  .inner-header.error:before {
    background-color: #fb999f; }
  .inner-header.inactive:before {
    background-color: #e0e0e0; }
  .inner-header.finished:before {
    background-color: #adceec; }
  .inner-header.no-margin {
    margin-bottom: 0; }

.kpi {
  padding: 20px;
  display: inline-block;
  border-right: 1px solid #C3C3C3; }
  .kpi:last-child {
    border-right: 0; }

.kpi-value {
  font-weight: bold;
  font-size: 1.71429rem;
  margin-bottom: 5px;
  display: block;
  text-align: center; }

.kpi-key {
  color: #919191; }

.not-found-box {
  text-align: center;
  margin: 30px auto;
  width: 100%;
  max-width: 50%; }
  .not-found-box p {
    color: #9CA0B0;
    font-size: 1.28571rem; }

.scrolled-box {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll; }
  .scrolled-box.inner {
    max-height: 305px; }
  .scrolled-box.big {
    max-height: 410px; }
  .scrolled-box.bigger {
    max-height: 500px; }
  .scrolled-box.settings {
    max-height: calc(100vh - 180px); }

.scrolled-content {
  max-height: calc(100vh - 90px);
  overflow-x: hidden;
  overflow-y: scroll; }
  .scrolled-content.inner {
    padding: 30px 30px 100px 30px; }

.min-box {
  min-height: 400px; }

.detail-list-holder {
  min-height: 100px;
  position: relative; }

.detail-list {
  box-shadow: none;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #C3C3C3;
  border-top: 0 none;
  list-style: none;
  background-color: #FFF;
  margin-bottom: 20px; }
  .detail-list li a {
    display: block;
    padding: 10px;
    color: #C3C3C3;
    font-weight: normal;
    font-size: 0.85714rem;
    text-decoration: none;
    border-bottom: 1px solid #C3C3C3; }
    .detail-list li a.active {
      color: #606060;
      font-weight: 500; }
    .detail-list li a:hover {
      color: #606060;
      background-color: #f2f2f2; }
  .detail-list li:last-child a {
    border: 0 none; }

.badge {
  color: #FFF;
  background-color: #FAAF00;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
  padding: 0px 5px;
  vertical-align: middle;
  height: 24px;
  border-radius: 5px;
  margin-left: 10px; }
  .badge.small {
    line-height: 175%;
    height: 20px;
    padding: 0 5px;
    font-size: 0.85714rem;
    margin-left: 0; }

.label {
  display: inline-block;
  margin-right: 5px;
  background-color: #276AA6;
  color: #FFF;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  white-space: nowrap; }
  .label.warning {
    background-color: #FCF6EA;
    color: #FAAF00; }

/*------------------------------------------------------------------------------Loaders */
.box-loader {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.loader {
  border: 3px solid #cccccc;
  border-top: 3px solid #276AA6;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 35%; }

/*------------------------------------------------------------------------------Screen */
.screen {
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 9999999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: scroll; }

.modal {
  background-color: #FFF;
  max-width: 900px;
  margin: 0 auto;
  top: 10px;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  padding: 0; }
  .modal.modal-small {
    max-width: 750px; }
  .modal.modal-smallest {
    max-width: 450px; }
  .modal.modal-min-height {
    min-height: calc(100vh - 50px);
    height: auto; }
  .modal.h-100 {
    height: 100%; }

.modal-header {
  border-bottom: 1px solid #C3C3C3;
  padding: 20px 30px; }
  .modal-header h3 {
    margin-bottom: 0;
    text-transform: none;
    font-size: 1.14286rem; }

.iframe-demo {
  border: 0 none;
  height: 550px;
  width: 100%; }

/*------------------------------------------------------------------------------Disclaimers */
.disclaimer {
  border-radius: 5px;
  margin-bottom: 10px; }
  .disclaimer:last-child {
    margin-bottom: 0; }
  .disclaimer.error, .disclaimer.errors {
    border: 1px solid #F73742;
    color: #F73742;
    background-color: #FDDDDF; }
    .disclaimer.error a, .disclaimer.errors a {
      color: #F73742; }
  .disclaimer.success {
    border: 1px solid #38C14F;
    color: #38C14F;
    background-color: #EAFFED; }
    .disclaimer.success a {
      color: #38C14F; }
  .disclaimer.warning {
    border: 1px solid #FAAF00;
    color: #FAAF00;
    background-color: #FCF6EA; }
    .disclaimer.warning a {
      color: #FAAF00; }
  .disclaimer.floated {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px; }
  .disclaimer p {
    padding: 20px; }
  .disclaimer .disclaimer-list {
    list-style: none;
    border-top: 1px solid; }
    .disclaimer .disclaimer-list li {
      padding: 10px;
      border-bottom: 1px solid; }
      .disclaimer .disclaimer-list li:last-child {
        border: 0 none; }
      .warning .disclaimer .disclaimer-list li {
        border-color: #FAAF00; }
    .warning .disclaimer .disclaimer-list {
      border-color: #FAAF00; }

/*------------------------------------------------------------------------------Tables */
table {
  width: 100%; }
  table tr:hover .table-action-buttons-holder {
    opacity: 1;
    visibility: visible; }
  table tr:hover td {
    background-color: #fcfcfc; }
  table th {
    font-weight: normal;
    color: #B9C3D0;
    text-align: left;
    padding: 15px 20px;
    white-space: nowrap; }
  table td {
    padding: 15px 20px;
    vertical-align: top;
    border-top: 1px solid #C3C3C3;
    transition: all 350ms ease; }
  table.small th, table.small td {
    padding: 10px; }
  table.no-padding th {
    padding: 15px; }
  table.no-padding td {
    padding: 0; }
  table.cursored td:hover {
    cursor: pointer; }

.table-wrapp {
  padding: 10px; }

.table-action-buttons-holder {
  white-space: nowrap; }

/*------------------------------------------------------------------------------Dropdown */
.dropdown-holder {
  position: absolute;
  right: 20px;
  top: 6px;
  z-index: 99999; }

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  min-width: 250px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 350ms ease;
  border-radius: 5px 0px 5px 5px;
  z-index: 1; }
  .dropdown.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  .dropdown ul {
    list-style: none; }
    .dropdown ul li {
      padding: 0;
      border-bottom: 0 none; }
      .dropdown ul li a {
        display: block;
        border-bottom: 1px solid #C3C3C3;
        text-decoration: none;
        padding: 20px; }
      .dropdown ul li:last-child a {
        border-bottom: 0 none; }

/*------------------------------------------------------------------------------Datepicker */
.react-datepicker__input-container input {
  padding-left: 30px; }

.react-datepicker-popper {
  z-index: 2; }

.react-datepicker__navigation {
  font-size: 0;
  color: transparent;
  line-height: 0; }

/*------------------------------------------------------------------------------Tabs */
.tabs-holder {
  width: 100%;
  position: relative; }

.tabs-buttons-holder {
  position: absolute;
  bottom: calc(100% + 20px); }
  .tabs-buttons-holder.inner {
    position: static; }
    .tabs-buttons-holder.inner .btn-tab {
      border-bottom: #F4F4F7; }
      .tabs-buttons-holder.inner .btn-tab.active {
        background-color: #FFF;
        border-bottom: 0 none; }
        .tabs-buttons-holder.inner .btn-tab.active:after {
          background-color: #FFF; }
  .tabs-buttons-holder.small .btn-tab {
    font-size: 0.85714rem;
    padding: 10px 15px; }
  .tabs-buttons-holder.horizontal-padding {
    padding-left: 10px;
    padding-right: 10px; }

.tab-panel {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: opacity 300ms ease; }
  .tab-panel.active {
    opacity: 1;
    visibility: visible;
    max-height: none; }

/*------------------------------------------------------------------------------Calendar */
.definition {
  width: 70%; }
  .definition .calendar-holder {
    width: 60%; }

.calendar-holder {
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: 1px solid #C3C3C3;
  padding-bottom: 20px; }
  .calendar-holder:last-child {
    border: 0 none;
    padding-bottom: 0;
    margin-bottom: 0; }

.calendar-title {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 5px;
  display: inline-block;
  font-size: 0.85714rem; }

.calendar-table {
  width: 100%; }
  .calendar-table thead tr th {
    font-size: 0.85714rem;
    padding: 5px; }
  .calendar-table tbody tr td {
    text-align: center;
    font-size: 0.85714rem;
    padding: 5px;
    border: 0 none;
    border-bottom: 1px solid #FFF; }
    .calendar-table tbody tr td.active {
      background-color: #D4E8FA;
      color: #276AA6; }
      .calendar-table tbody tr td.active.past-day {
        background-color: #ffd77b;
        color: #606060; }
    .calendar-table tbody tr td.selected {
      position: relative; }
      .calendar-table tbody tr td.selected:after {
        content: "";
        background-color: #FAAF00;
        display: block;
        border-radius: 100%;
        width: 5px;
        height: 5px;
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        margin: 0 auto; }
    .calendar-table tbody tr td.today {
      background-color: #276AA6;
      color: #FFF; }

.conversation-bubble {
  border-radius: 5px;
  padding: 10px;
  color: #276AA6;
  margin-right: 10%;
  margin-bottom: 20px; }
  .conversation-bubble.in {
    background-color: #D4E8FA;
    margin-left: 10%;
    margin-right: 0; }
  .conversation-bubble p {
    margin-bottom: 0px; }

/*------------------------------------------------------------------------------Header */
.main-header {
  padding: 0 20px;
  border-bottom: 1px solid #C3C3C3;
  background-color: #FFF;
  position: relative; }

.header-logo {
  display: inline-block;
  max-width: 80px; }

.main-navigation {
  display: inline-block;
  margin-left: 65px; }
  .main-navigation a {
    display: inline-block;
    padding: 15px 20px;
    color: #113758;
    font-size: 1rem;
    text-decoration: none;
    transition: background 300ms ease; }
    .main-navigation a:hover {
      background-color: #fafafa; }
    .main-navigation a.active {
      font-weight: 500;
      position: relative; }
      .main-navigation a.active:after {
        content: "";
        height: 3px;
        background-color: #276AA6;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -2px; }
    .main-navigation a .icon-element {
      margin-right: 15px; }

.header-actions-holder {
  position: absolute;
  top: -5px;
  right: 20px; }

/*------------------------------------------------------------------------------ASISTENTE */
.assistant-holder {
  position: relative;
  border: 1px solid #C3C3C3; }

.assistant-main-header {
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999; }
  .whatsapp .assistant-main-header {
    background-color: #005e55 !important; }

.assistant-main-header-col {
  display: inline-block;
  vertical-align: middle; }
  .assistant-main-header-col.left {
    width: 50px;
    margin-right: 10px; }
  .assistant-main-header-col.right {
    width: calc(100% - 65px); }

.assistant-title {
  margin-bottom: 0;
  font-size: 1.14286rem;
  text-transform: none; }

.assistant-subtitle {
  font-size: 0.92857rem; }
  .whatsapp .assistant-subtitle {
    display: none; }

.assistant-conversation-holder {
  background-color: #FFF;
  padding: 80px 10px 10px 10px;
  height: calc(100vh - 180px);
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
  position: relative; }
  .assistant-conversation-holder .error-assistant {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .whatsapp .assistant-conversation-holder {
    position: relative;
    background-color: #efe9e7 !important; }
    .whatsapp .assistant-conversation-holder:before {
      content: "";
      position: absolute;
      background: url("../images/whatsapp-bg.png") repeat #efe9e7 !important;
      opacity: 0.2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1; }

.assistant-input-user-holder {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e7e7e7; }

.assistant-form-wrapp {
  transition: all .3s ease;
  background-color: #FFF; }

.assistant-input-user {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 45px);
  margin-bottom: 0;
  height: 50px;
  border-bottom: 0 none; }

.assistant-action-button-holder {
  display: inline-block;
  position: relative; }
  .assistant-action-button-holder:hover .assistant-btn-light + .assistant-tooltip {
    opacity: 1;
    visibility: visible; }

.assistant-btn {
  font-family: Open sans,sans-serif;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  width: auto;
  height: auto;
  line-height: 120%;
  transition: background .3s ease;
  color: #fff;
  transition: all .35s ease;
  border-radius: 10px;
  border: 0 none;
  margin-right: 10px;
  margin-bottom: 10px; }
  .assistant-btn:last-child {
    margin-right: 0; }
  .assistant-btn.assistant-btn-light {
    background-color: transparent;
    border: 1px solid;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    padding: 0;
    box-shadow: none; }
  .whatsapp .assistant-btn {
    background-color: #00897b !important;
    border-color: #00897b !important; }

.assistant-tooltip {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: calc(100% + 5px);
  white-space: nowrap;
  right: 0;
  transition: opacity .3s ease; }

.assistant-spinner-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  height: 100%; }

.assistant-spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 10px;
  position: relative;
  top: calc(50% - 25px);
  margin: 0 auto;
  text-indent: -9999em;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  border-right: 3px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  border-left: 3px solid rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.assistant-conversation-bubble {
  margin-bottom: 15px; }
  .assistant-conversation-bubble.to {
    text-align: right; }

.assistant-bubble {
  display: inline-block;
  background-color: #e7e7e7;
  padding: 15px;
  border-radius: 30px;
  margin-top: 3px;
  max-width: calc(100% - 70px);
  color: #6b6a6f;
  font-size: .875rem; }
  .whatsapp .assistant-bubble {
    max-width: calc(100% - 10px);
    background-color: #FFF !important;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
    color: #1e281e !important; }

.to .assistant-bubble {
  text-align: left; }
  .whatsapp .to .assistant-bubble {
    background-color: #e1ffc8 !important;
    border-radius: 5px 0px 5px 5px; }

.assistant-response-image {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  margin-right: 10px; }
  .whatsapp .assistant-response-image {
    display: none; }

.assistant-loader {
  text-align: center;
  width: 100px;
  position: absolute;
  bottom: 100%;
  height: 30px;
  margin: 0 auto;
  left: 0;
  right: 0; }
  .assistant-loader .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    animation: wave 1.3s linear infinite; }
    .assistant-loader .dot:nth-child(2) {
      animation-delay: -1.1s; }
    .assistant-loader .dot:nth-child(3) {
      animation-delay: -0.9s; }

@keyframes wave {
  0%, 60%, 100% {
    transform: initial; }
  30% {
    transform: translateY(-15px); } }

.calendar-assistant-holder {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  max-width: 350px;
  border: 1px solid #e7e7e7;
  margin-right: 10px; }

.calendar-assistant-title {
  font-weight: bold;
  text-align: center;
  display: block;
  margin-bottom: 5px; }

.calendar-assistant-table th {
  font-weight: bold;
  color: #6b6a6f; }

.calendar-assistant-table td {
  color: #e7e7e7; }
  .calendar-assistant-table td.active {
    font-weight: bold; }

.calendar-assistant-table td, .calendar-assistant-table th {
  padding: 5px;
  font-size: 0.85714rem;
  border-bottom: 1px solid #e7e7e7; }

.calendar-assistant-table tr:last-child td {
  border-bottom: 0 none; }

.step-form-channel-menu {
  list-style: none;
  padding: 0;
  width: 17%;
  display: inline-block;
  vertical-align: top; }
  .step-form-channel-menu li {
    padding: 20px;
    border-bottom: 1px solid #C3C3C3;
    color: #919191;
    position: relative;
    cursor: pointer;
    transition: all 300ms ease;
    font-size: 0.85714rem; }
    .step-form-channel-menu li.active, .step-form-channel-menu li:hover {
      color: #276AA6;
      font-weight: bold; }

.step-form-channel-title {
  font-weight: bold; }

.step-form-channel-menu-check {
  position: absolute;
  top: 14px;
  right: 20px; }

.step-form-channel-content {
  width: 83%;
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid #C3C3C3;
  min-height: 300px;
  position: relative; }

.draftJsToolbar__toolbar__dNtBH:before,
.draftJsToolbar__toolbar__dNtBH:after {
  display: none; }

.mention-suggestions > div {
  max-height: 250px;
  overflow: scroll; }

.color-picker {
  border: 1px solid #C3C3C3; }
  .color-picker .color-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin: 5px; }
  .color-picker .color-box-text {
    display: inline-block;
    width: calc(100% - 30px);
    border-left: 1px solid #C3C3C3;
    vertical-align: middle;
    padding: 10px; }

.color-picker-holder .sketch-picker {
  position: absolute;
  z-index: 1; }

.toolbar {
  width: 20%;
  position: fixed;
  padding: 20px;
  left: 0;
  height: calc(100vh - 125px);
  background-color: #FFF;
  padding-left: 45px;
  border-right: 1px solid #C3C3C3; }

.content-with-toolbar {
  width: 80%;
  margin-left: 20%; }

.content-with-toolbar-scrolled {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 190px); }

.content-tool-bar {
  padding: 10px 20px;
  border-bottom: 1px solid #C3C3C3; }

.character-counter {
  float: right;
  font-weight: normal; }
